.pos-card {
  background-color: white;
  padding: 25px 16px;
  border-radius: 15px;
  min-height: 180px;

  .pos-card__title {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    letter-spacing: 0.16px;
    line-height: 20px;
  }

  &.pos-card--translucent {
    background-color: rgba(#F5FCFF, 0.9);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }

  .step-header {
    margin-bottom: 30px;
  }

  .step-number-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .step-number-wrapper__semi-circle {
      border: 6px solid transparent;
      border-top-color: rgba(49, 115, 195, 0.15);
      border-right-color: rgba(49, 115, 195, 0.15);
      border-radius: 50px;
      width: 40px;
      height: 40px;
      transform: rotate(-45deg);
    }

    .step-number-wrapper__number {
      position: absolute;
      top: 8px;
      font-weight: 700;
    }
  }
}

