html,
body {
  font-size: 14px;

  p {
    font-size: 14px;
    line-height: 24px;
  }

  h1 {
    font-size: 33px;
    line-height: 44px;
  }

  h2 {
    font-size: 20px;
    line-height: 35px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
    color: #283F63;
  }

  h6 {
    font-size: 12px;
  }
}
