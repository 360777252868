@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Colors */
$primary-color: #3FCCA6;
$secondary-color: #3173C3;
$lite-gray: #D8D8D8;
$darker-gray: #757575;

/* Gradients */
$primary-gradient: linear-gradient(90deg, $primary-color 0%, #5BEFB5 100%);
$secondary-gradient: linear-gradient(90deg, #283F63 0%, $secondary-color 100%);

/* Fonts */
$font-primary-color: #50505a;
$font-primary-stack: 'Poppins', Roboto, 'Fire Sans', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif;

//---------------------//
// Bootstrap Variables //
//---------------------//

$body-bg: #3878C2;
$body-color: $font-primary-color;

$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'light': $lite-gray,
  'dark': $darker-gray,
);

$font-family-sans-serif: $font-primary-stack;

$btn-padding-y: 0.714rem;

// custom-range input

$custom-range-track-width:          100%;
$custom-range-track-height:         4px;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             #d2dae3;
$custom-range-track-border-radius:  1rem;
$custom-range-track-box-shadow:     none;

$custom-range-thumb-width:         22px;
$custom-range-thumb-height:        22px;
$custom-range-thumb-bg:            white;
$custom-range-thumb-border:        0 !default;
$custom-range-thumb-border-radius: 50%;
$custom-range-thumb-box-shadow:    2px 0px 7px rgba(0, 0, 0, 0.1);
$custom-range-thumb-active-bg:     $lite-gray;

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";
// scss-docs-end import-stack
