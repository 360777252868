.pos-navbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  background-color: #283F63;
  color: white;
  z-index: 1;

  .pos-navbar__go-back {
    background-color: transparent;
    border: none;
    left: 20px;
    position: absolute;
  }

  .pos-navbar__title {
    margin: 0;
  }
}
