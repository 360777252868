/* Buttons */
a, button {
  &.btn {
    min-height: 34px;
    min-width: 34px;
    border-radius: 25px;
    color: white;

    &.btn-gradient-primary {
      background-color: $primary-color;
      background: $primary-gradient;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 20px;
      border-radius: 25px;
      min-height: 40px;

      &.btn-sm {
        min-height: 34px;
        line-height: 20px;
      }

      &:hover {
        background: linear-gradient(90deg, #5BEFB5 0%, $primary-color 100%);
      }
    }

    &.btn-gradient-secondary {
      background-color: $secondary-color;
      background: $secondary-gradient;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 20px;
      border-radius: 25px;
      min-height: 40px;

      &.btn-sm {
        min-height: 34px;
        line-height: 20px;
      }

      &:hover {
        background: linear-gradient(90deg, $secondary-color 0%, #283F63 100%);
      }
    }

    &.btn-light {
      background-color: white;
      border-color: #E7E7E7;

      &:hover {
        background-color:  darken(#E7E7E7, 10%);
      }
    }

    &.btn-facebook {
      background-color: #3B5998;

      &:hover {
        background-color: lighten(#3B5998, 10%);
      }
    }

    &.btn-facebook, &.btn-twitter {
      width: 34px;
      height: 34px;
      fill: transparent;
    }

    &.btn-twitter {
      background-color: #00ACED;

      &:hover {
        background-color: darken(#00ACED, 10%);
      }
    }

    &.btn-light-blue {
      background-color: #EFF3F8;

      &:hover {
        background-color: darken(#EFF3F8, 5%);
      }
    }

    &.btn-back-step {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      width: 34px;
    }

    &.btn-rounded {
      border-radius: 50%;
    }
  }
}
