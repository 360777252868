@-webkit-keyframes fadeIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(45deg);
    border-right-color: var(--primary);
  }

  17% {
    border-bottom-color: var(--primary);
  }

  34% {
    -webkit-transform: rotate(90deg);
    border-right-color: var(--primary);
    border-bottom-color: var(--primary);
  }

  51% {
    -webkit-transform: rotate(270deg);
    border-right-color: transparent;
  }

  85% {
    border-bottom-color: var(--primary);
  }

  100% {
    -webkit-transform: rotate(360deg);
    border-bottom-color: transparent;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(45deg);
    border-right-color: var(--primary);
  }

  17% {
    border-bottom-color: var(--primary);
  }

  34% {
    -webkit-transform: rotate(90deg);
    border-right-color: var(--primary);
    border-bottom-color: var(--primary);
  }

  51% {
    -webkit-transform: rotate(270deg);
    border-right-color: transparent;
  }

  85% {
    border-bottom-color: var(--primary);
  }

  100% {
    -webkit-transform: rotate(360deg);
    border-bottom-color: transparent;
  }
}
