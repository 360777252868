.max-w-300 {
  max-width: 300px;
}

.w-46 {
  width: 46%;
}

.pos-divider {
  position: relative;
  height: 25px;
  width: 100%;
  background-color: white;
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  font-weight: 500;
}

.no-h-gutters {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
