.pos-section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .pos-section__header {
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    min-height: 54px;

    &.pos-section__header--dark {
      background-color: #283F63;
    }
  }

  .pos-section__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}
