@import '../../../styles/animations';

.pos-preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: rgba(#fff, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .pos-preloader-wrapper {
    border: 10px solid #e7e7e7;
    width: 120px;
    height: 120px;
    position: relative;

    .pos-preloader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -10px;
      left: -10px;
      border: 10px solid transparent;
      box-sizing: content-box;
      -webkit-animation: spin 3s ease-in-out infinite;
      /* Safari */
      animation: spin 3s ease-in-out infinite;
    }
  }

  .pos-preloader-wrapper, .pos-preloader {
    margin: 0;
    padding: 0;
    border-radius: 100%;
  }
}
