/* General Form Styles */
form {
  label {
    margin: 0;
    color: #283f63;
    font-size: 14px;
    font-weight: 500;
  }

  hr {
    background-color: $lite-gray;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.dollar-prefix {
  position: relative;

  &::before {
    content: "$";
    position: absolute;
    left: 25px;
    top: 8px;
  }
}

.pos-form-control {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  border: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  padding: 0 20px;
  color: $font-primary-color;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: $font-primary-color;
  }

  &::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: $font-primary-color;
  }

  &:-ms-input-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: $font-primary-color;
  }

  &:-moz-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: $font-primary-color;
  }
}

/* Custom Switches */
.pos-form-checkbox {
  .custom-control {
    min-height: 2.1rem;
  }

  .custom-switch {
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $font-primary-color;
      background-image: $primary-gradient;
      border-color: $primary-color;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #ffffff;
      transform: translateX(24px);
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25);
    }

    .custom-control-label {
      cursor: pointer;

      &::before {
        height: 26px;
        width: 50px;
        left: -3.25rem;
        border-radius: 26px;
      }

      &::after {
        height: 22px;
        width: 22px;
        left: calc(-3.25rem + 2px);
        border-radius: 50%;
      }
    }
  }
}

/* Custom Radios */
.pos-form-radio {
  label {
    display: block;
    height: 38px;
    width: 100%;
    background-color: white;
    line-height: 38px;
    padding: 0 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.16px;

    &:hover {
      cursor: pointer;
    }
  }

  .custom-control {
    min-height: 2.1rem;
  }

  .custom-control-input:checked + label {
    border: 2px solid $primary-color;
  }

  .custom-radio {
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      border: none;
      background-image: unset;
    }

    .custom-control-label {
      cursor: pointer;

      &::before,
      &::after {
        left: -1.75rem;
        height: 25px;
        width: 25px;
      }

      &::after {
        border: 1px solid gray;
        border-radius: 50%;
      }
    }
  }
}

/* Custom Sliders */
.custom-range {
  height: 4px;
  background-color: #d2dae3;

  &::-webkit-slider-runnable-track {
    background-color: unset;
  }

  &::-moz-range-track {
    background-color: unset;
  }

  &::-ml-track {
    background-color: unset;
  }

  &::-ms-fill-lower {
    background-color: unset;
  }

  &:focus {
    &::-webkit-slider-thumb {
      border: none;
      box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
    }

    /* All the same stuff for Firefox */
    &::-moz-range-thumb {
      border: none;
      box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
    }

    /* All the same stuff for IE */
    &::-ml-thumb {
      border: none;
      box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
    }
  }
}

.pos-horizontal-radios {
  background-color: #DFE6ED;
  border-radius: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 38px;

  .pos-horizontal-radios__option {
    width: 100%;
    text-align: center;
    height: 32px;
    line-height: 32px;
    padding: 0 3px;

    label {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 13px;
      font-weight: 400;
      color: black;
      letter-spacing: 0.16px;
      border-radius: 18px;
      cursor: pointer;
    }

    .custom-control-input:checked + label {
      background-color: white;
    }
  }
}
